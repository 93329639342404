<template>
  <Layout>
    <section class="main-section">
      <div class="text-content">
        <p>POLITYKA PRYWATNOŚCI</p>
      </div>
    </section>

    <h1 class="text-center py-4">Zakładka w trakcie prac</h1>
<!--    <section class="pdf-section">-->
<!--      <embed src="/privacy-policy.pdf" frameborder="0" height="100%" class="pdf-embed">-->
<!--    </section>-->
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  name: 'HomeView',
  components: {
    Layout
  }
}
</script>

<style scoped>
.main-section {
  text-align: left;
  padding-top: 4em;
  padding-bottom: 4em;
}

.text-content {
  text-align: center;
}

.text-content p {
  font-size: 3em;
}
</style>