<template>
  <section class="download-app">
    <div class="download-app-content">
      <h1><span class="pojazd-text-primary">Pobierz aplikację</span> już teraz</h1>
      <p>
        Serwisuj samochód szybko i wygodnie umawiaj wizyty
        w warsztacie z poziomu komórki! Bądź na bieżąco podczas
        pobytu samochodu w warsztacie! Korzystaj z promocji
        z okolicznych serwisów!
      </p>

      <div class="button-items">
        <div class="btn app-store">
          <img src="@/assets/img/appstore.png" alt="App Store">
        </div>

        <div class="btn google-play">
          <img src="@/assets/img/google-play.png" alt="Google Play">
        </div>
      </div>
    </div>
  </section>

  <footer>
    <div class="footer-main">
      <div class="footer-section about">
        <h4>O nas</h4>
        <p>Serwisuj samochód szybko i wygodnie, umawiaj wizyty w warsztacie z poziomu komórki!
          Bądź na bieżąco z naprawami i korzystaj z najlepszych ofert serwisowych w Twojej okolicy.</p>
      </div>

      <div class="footer-section company-info">
        <h4>Network24</h4>
        <div class="legal-info">
          <p>NIP: 4990704014</p>
          <p>REGON: 540055922</p>
        </div>
      </div>

      <div class="footer-section contact">
        <h4>Kontakt</h4>
        <div class="contact-details">
          <a href="tel:510622563" class="contact-item">
            <i class="fa fa-phone"></i>
            <span>510 622 563</span>
          </a>
          <a href="mailto:kontakt@pojazd24.pl" class="contact-item">
            <i class="fa fa-envelope"></i>
            <span>kontakt@pojazd24.pl</span>
          </a>
        </div>
      </div>

      <div class="footer-section social">
        <div class="social-links">
          <a href="#" aria-label="Facebook"><i class="fa fa-facebook-square"></i></a>
          <a href="#" aria-label="WhatsApp"><i class="fa fa-whatsapp"></i></a>
          <a href="#" aria-label="Instagram"><i class="fa fa-instagram"></i></a>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="footer-links">
        <RouterLink :to="{ name: 'privacy-policy' }">Polityka prywatności</RouterLink>
        <span class="separator">|</span>
        <RouterLink :to="{ name: 'rules' }">Regulamin</RouterLink>
      </div>
      <p class="copyright">&copy; {{ currentYear }} Network24. Wszelkie prawa zastrzeżone.</p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
}
</script>

<style scoped>
footer {
  background-color: #238085;
  color: #ffffff;
  padding: 60px 0 0;
  font-family: "Comfortaa", sans-serif;
}

.footer-main {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 40px;
  padding: 0 20px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-section h4 {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}

/* About Section */
.about p {
  color: #ffffff;
  line-height: 1.6;
  font-size: 14px;
}

/* Company Info Section */
.legal-info p {
  color: #ffffff;
  margin: 8px 0;
  font-size: 15px;
}

/* Contact Section */
.contact-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s ease;
}

.contact-item:hover {
  color: #e6ffff;
}

.contact-item i {
  font-size: 20px;
  width: 24px;
}

/* Social Media Section */
.social {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.social-links {
  display: flex;
  gap: 25px;
}

.social-links a {
  color: #ffffff;
  font-size: 38px;
  transition: all 0.2s ease;
}

.social-links a:hover {
  color: #e6ffff;
  transform: translateY(-3px);
}

/* Footer Bottom */
.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  //background-color: rgba(0, 0, 0, 0.05);
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
}

.footer-links a:hover {
  color: #e6ffff;
}

.separator {
  color: #ffffff;
  margin: 0 10px;
}

.copyright {
  color: #ffffff;
  font-size: 14px;
}

@media (max-width: 1024px) {
  .footer-main {
    grid-template-columns: 1fr 1fr;
  }

  .about {
    grid-column: span 2;
  }

  .social {
    justify-content: flex-start;
  }
}

@media (max-width: 640px) {
  .footer-main {
    grid-template-columns: 1fr;
    gap: 30px;
    text-align: center;
  }

  .about {
    grid-column: span 1;
  }

  .contact-details {
    align-items: center;
  }

  .social {
    justify-content: center;
  }

  .social-links {
    gap: 40px;
  }

  .social-links a {
    font-size: 42px;
  }
}
</style>